<template>
	<div class="deviceData">
		<div class="main-filter">
			<el-form inline :model="formInline" class="demo-form-inline">
				<el-form-item prop="provinceDictionaryItem" label="省：">
					<el-select v-model="formInline.provinceDictionaryItem" placeholder="请选择省" @change="changeEconomize($event, 1)">
						<el-option v-for="item in economizeList" :key="item.id" :label="item.title" :value="item.code"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item prop="cityDictionaryItem" label="市：">
					<el-select v-model="formInline.cityDictionaryItem" placeholder="请选择市" @change="changeEconomize($event, 2)">
						<el-option v-for="item in marketList" :key="item.id" :label="item.title" :value="item.code"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item prop="regionCode" label="区县：">
					<el-select v-model="formInline.regionCode" placeholder="请选择区县" @change="changeEconomize($event, 3)">
						<el-option v-for="item in districtList" :key="item.id" :label="item.title" :value="item.code"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item prop="townCode" label="乡镇：">
					<el-select v-model="formInline.townCode" placeholder="请选择" @change="changeEconomize($event, 4)">
						<el-option v-for="item in townList" :key="item.id" :label="item.title" :value="item.code"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item prop="villageCode" label="村：">
					<el-select v-model="formInline.villageCode" placeholder="请选择">
						<el-option v-for="item in villageList" :key="item.id" :label="item.title" :value="item.code"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="getData" icon="el-icon-search">搜索</el-button>
				</el-form-item>
			</el-form>
		</div>
		<div class="container">
			<div class="deviceData-title">
				<div class="deviceData-title-content">
					<div class="institutionTotal">
						<img src="@/assets/imgs/peoples.png" alt="" />
						<div class="institutionTotal-content">
							<p>设备数</p>
							<p>{{ datas.smartDeviceTotal || 0 }}</p>
						</div>
					</div>
				</div>
			</div>
			<div class="content">
				<el-row :gutter="12">
					<el-col :span="12">
						<div class="chartsClass" ref="deviceHeight">
							<p>
								<img src="@/assets/imgs/datas.png" alt="" />
								设备分布数量统计
							</p>
							<div id="device" :style="{ width: '100%', height: deviceHeight + 'px' }"></div>
						</div>
					</el-col>
					<el-col :span="12">
						<div class="chartsClass" ref="hospitalChartsHeight">
							<p>
								<img src="@/assets/imgs/datas.png" alt="" />
								设备使用次数
							</p>
							<div id="hospitalCharts" :style="{ width: '100%', height: hospitalChartsHeight + 'px' }"></div>
						</div>
					</el-col>
				</el-row>
				<div class="chartsClass w100 usageIncomeClass" ref="usageIncome">
					<p>
						<img src="@/assets/imgs/datas.png" alt="" />
						使用收益
					</p>
					<div id="usageIncome" :style="{ width: '100%', height: usageIncome + 'px' }"></div>
					<div class="dateChange">
						<p :class="{ activeClass: checkDate === 1 }" @click="changeDate(1)">近一周</p>
						<p :class="{ activeClass: checkDate === 2 }" @click="changeDate(2)">近30天</p>
						<p :class="{ activeClass: checkDate === 3 }" @click="changeDate(3)">近一年</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import 'echarts-liquidfill';
import * as echarts from 'echarts';
export default {
	name: 'deviceData',
	data() {
		return {
			formInline: {
				provinceDictionaryItem: null,
				cityDictionaryItem: null, //市
				regionCode: null, //区县
				townCode: null,
				villageCode: null,
			},
			townList: [], //乡镇
			villageList: [], //村
			districtList: [], //区县
			marketList: [], //市
			economizeList: [], //省
			deviceHeight: 318, // 传染病各类总数
			hospitalChartsHeight: 318, // 传染病各类占比
			usageIncome: 340, // 使用收益
			checkDate: 1, // 1近一周 2近30天 3近一年
			regionCode: null,
			datas: {},
		};
	},
	mounted() {
		this.$nextTick(() => {
			this.deviceHeight = this.$refs.deviceHeight.offsetHeight - 90 > 318 ? this.$refs.deviceHeight.offsetHeight - 90 : 318;
			this.hospitalChartsHeight = this.$refs.hospitalChartsHeight.offsetHeight - 90 > 318 ? this.$refs.hospitalChartsHeight.offsetHeight - 90 : 318;
			this.usageIncome = this.$refs.usageIncome.offsetHeight - 90 > 340 ? this.$refs.usageIncome.offsetHeight - 90 : 340;
		});
		this.getEconomizeList();
		this.getData();
	},
	methods: {
		// 获取省数据
		getEconomizeList() {
			this.$http
				.get(this.api.getChinaRegions)
				.then((res) => {
					if (res.data && res.data.success) {
						this.economizeList = res.data.collection || [];
						this.economizeList.unshift({
							title: '全部',
							code: null,
						});
					}
				})
				.catch((e) => {});
		},
		// 获取市/区县
		changeEconomize(data, type) {
			this.regionCode = data;
			switch (type) {
				case 1:
					this.formInline.cityDictionaryItem = null;
					this.formInline.townCode = null;
					this.formInline.villageCode = null;
					this.formInline.regionCode = null;
					this.marketList = [];
					this.districtList = [];
					this.townList = [];
					this.villageList = [];
					if (!data || data == '') return;
					this.$http
						.get(this.api.getChinaRegions, { params: { code: data } })
						.then((res) => {
							if (res.data && res.data.success) {
								this.marketList = res.data.collection || [];
								this.marketList.unshift({
									title: '全部',
									code: null,
								});
							}
						})
						.catch((e) => {});
					break;
				case 2:
					this.formInline.townCode = null;
					this.formInline.villageCode = null;
					this.formInline.regionCode = null;
					this.districtList = [];
					this.townList = [];
					this.villageList = [];
					if (!data || data == '') return;
					this.$http
						.get(this.api.getChinaRegions, { params: { code: data } })
						.then((res) => {
							if (res.data && res.data.success) {
								this.districtList = res.data.collection || [];
								this.districtList.unshift({
									title: '全部',
									code: null,
								});
							}
						})
						.catch((e) => {});
					break;
				case 3:
					this.formInline.townCode = null;
					this.formInline.villageCode = null;
					this.townList = [];
					this.villageList = [];
					if (!data || data == '') return;
					this.$http
						.get(this.api.getChinaRegions, { params: { code: data } })
						.then((res) => {
							if (res.data && res.data.success) {
								this.townList = res.data.collection || [];
								this.townList.unshift({
									title: '全部',
									code: null,
								});
							}
						})
						.catch((e) => {});
					break;
				case 4:
					this.formInline.villageCode = null;
					this.villageList = [];
					if (!data || data == '') return;
					this.$http
						.get(this.api.getChinaRegions, { params: { code: data } })
						.then((res) => {
							if (res.data && res.data.success) {
								this.villageList = res.data.collection || [];
								this.villageList.unshift({
									title: '全部',
									code: null,
								});
							}
						})
						.catch((e) => {});
					break;
				default:
					break;
			}
		},
		// 查询条件
		getData() {
			let regionCode = this.formInline.villageCode ? this.formInline.villageCode : this.regionCode;
			this.$http
				.get(this.api.getSmartDeviceAnalysis, { params: { regionCode, amountQueryType: this.checkDate } })
				.then((res) => {
					if (res.data && res.data.success) {
						this.datas = res.data.collection[0] || {};
						let dataArr1 = [
							{
								name: '省',
								value: this.datas.provinceSmartDeviceTotal || 0,
							},
							{
								name: '市',
								value: this.datas.citySmartDeviceTotal || 0,
							},
							{
								name: '区县',
								value: this.datas.areaSmartDeviceTotal || 0,
							},
							{
								name: '乡镇',
								value: this.datas.townSmartDeviceTotal || 0,
							},
						];
						let dataArr2 = [
							{
								name: '省',
								value: this.datas.provinceSmartDeviceUseTotal || 0,
							},
							{
								name: '市',
								value: this.datas.citySmartDeviceUseTotal || 0,
							},
							{
								name: '区县',
								value: this.datas.areaSmartDeviceUseTotal || 0,
							},
							{
								name: '乡镇',
								value: this.datas.townSmartDeviceUseTotal || 0,
							},
						];
						let dataArr3 = res.data.collection[0].smartDeviceIncomeDtoList.reverse() || [];
						this.getdeviceData(dataArr1);
						this.getHospitalChartsData(dataArr2);
						this.getUsageIncomeData(dataArr3);
					}
				})
				.catch((e) => {});
		},
		// 获取设备分布数量统计
		getdeviceData(data) {
			let myChart = echarts.init(document.getElementById('device'));
			var dataStyle = {
				normal: {
					label: {
						show: false,
					},
					labelLine: {
						show: false,
					},
					shadowBlur: 40,
					borderWidth: 10,
					shadowColor: 'rgba(0, 0, 0, 0)', //边框阴影
				},
			};
			var placeHolderStyle = {
				normal: {
					color: '#F7F8FA',
					label: {
						show: false,
					},
					labelLine: {
						show: false,
					},
				},
				emphasis: {
					color: '#F7F8FA',
				},
			};
			myChart.setOption({
				backgroundColor: '#fff',
				title: {
					text: '{name|' + '共计(台)' + '}\n{val|' + this.datas.smartDeviceTotal + '}',
					textAlign: 'center',
					x: '23.5%',
					y: 'center',
					textStyle: {
						color: '#333333',
						rich: {
							name: {
								fontSize: 12,
								fontWeight: 'normal',
								color: '#666666',
								padding: [10, 0],
								align: 'center',
							},
							val: {
								fontSize: 32,
								fontWeight: 'bold',
								color: '#333333',
								align: 'center',
							},
						},
					},
				},
				tooltip: {
					trigger: 'item',
					show: false,
					formatter: '{b} : <br/>{d}%',
					backgroundColor: 'rgba(0,0,0,0.7)', // 背景
					padding: [8, 10], //内边距
					extraCssText: 'box-shadow: 0 0 3px rgba(255, 255, 255, 0.4);', //添加阴影
				},
				legend: {
					backgroundColor: '#F9FAFC',
					padding: [25, 32],
					width: 300,
					type: 'plain',
					icon: 'circle',
					orient: 'vertical',
					left: '45%',
					top: 'center',
					align: 'left',
					itemGap: 15,
					itemWidth: 10, // 设置宽度
					itemHeight: 10, // 设置高度
					symbolKeepAspect: false,
					textStyle: {
						color: '#333',
						rich: {
							name: {
								verticalAlign: 'right',
								align: 'left',
								width: 120,
								fontSize: 12,
							},
							value: {
								align: 'right',
								width: 60,
								fontSize: 12,
							},
						},
					},
					data: data.map((item) => item.name),
					formatter: function (name) {
						if (data && data.length) {
							for (var i = 0; i < data.length; i++) {
								if (name === data[i].name) {
									return '{name| ' + name + '}' + '{value| ' + data[i].value + '(台)}';
								}
							}
						}
					},
				},
				series: [
					{
						name: '',
						type: 'pie',
						roundCap: true,
						clockWise: false,
						radius: ['60%', '65%'],
						center: ['24%', '50%'],
						itemStyle: dataStyle,
						hoverAnimation: false,
						startAngle: 90,
						label: {
							borderRadius: 10,
						},
						data: [
							{
								value: data[0].value != 0 ? (data[0].value / this.datas.smartDeviceTotal) * 100 : 0,
								name: '省',
								itemStyle:
									data[0].value != 0
										? {
												normal: {
													color: '#165DFF',
												},
										  }
										: placeHolderStyle,
							},
							{
								value: 100 - (data[0].value != 0 ? (data[0].value / this.datas.smartDeviceTotal) * 100 : 0),
								name: '',
								tooltip: {
									show: false,
								},
								itemStyle: placeHolderStyle,
							},
						],
					},
					{
						name: 'Line 2',
						type: 'pie',
						roundCap: true,
						clockWise: false,
						radius: ['50%', '55%'],
						center: ['24%', '50%'],
						itemStyle: dataStyle,
						hoverAnimation: false,
						startAngle: 90,
						data: [
							{
								value: data[1].value != 0 ? (data[1].value / data[0].value) * 100 : 0,
								name: '市',
								itemStyle:
									data[1].value != 0
										? {
												normal: {
													color: '#02CADF',
												},
										  }
										: placeHolderStyle,
							},
							{
								value: 100 - (data[1].value / data[0].value) * 100,
								name: '',
								tooltip: {
									show: false,
								},
								itemStyle: placeHolderStyle,
							},
						],
					},
					{
						name: 'Line 3',
						type: 'pie',
						roundCap: true,
						clockWise: false,
						radius: ['40%', '45%'],
						center: ['24%', '50%'],

						itemStyle: dataStyle,
						hoverAnimation: false,
						startAngle: 90,
						data: [
							{
								value: data[2].value != 0 ? (data[2].value / data[1].value) * 100 : 0,
								name: '区县',
								itemStyle:
									data[2].value != 0
										? {
												normal: {
													color: '#F7BA1E',
												},
										  }
										: placeHolderStyle,
							},
							{
								value: 100 - (data[2].value / data[1].value) * 100,
								name: '',
								tooltip: {
									show: false,
								},
								itemStyle: placeHolderStyle,
							},
						],
					},
					{
						name: 'Line 3',
						type: 'pie',
						roundCap: true,
						clockWise: false,
						radius: ['30%', '35%'],
						center: ['24%', '50%'],
						itemStyle: dataStyle,
						hoverAnimation: false,
						startAngle: 90,
						data: [
							{
								value: data[3].value != 0 ? (data[3].value / data[2].value) * 100 : 0,
								name: '乡镇',
								itemStyle:
									data[3].value != 0
										? {
												normal: {
													color: '#04C59F',
												},
										  }
										: placeHolderStyle,
							},
							{
								value: 100 - (data[3].value / data[2].value) * 100,
								name: '',
								tooltip: {
									show: false,
								},
								itemStyle: placeHolderStyle,
							},
						],
					},
				],
			});
			window.addEventListener('resize', () => {
				myChart.resize();
			});
		},
		// 获取设备使用次数
		getHospitalChartsData(data) {
			let myChart = echarts.init(document.getElementById('hospitalCharts'));
			var dataStyle = {
				normal: {
					label: {
						show: false,
					},
					labelLine: {
						show: false,
					},
					shadowBlur: 40,
					borderWidth: 10,
					shadowColor: 'rgba(0, 0, 0, 0)', //边框阴影
				},
			};
			var placeHolderStyle = {
				normal: {
					color: '#F7F8FA',
					label: {
						show: false,
					},
					labelLine: {
						show: false,
					},
				},
				emphasis: {
					color: '#F7F8FA',
				},
			};
			myChart.setOption({
				backgroundColor: '#fff',
				title: {
					text: '{name|' + '共计(次)' + '}\n{val|' + this.datas.smartDeviceUseTotal + '}',
					textAlign: 'center',
					x: '23.5%',
					y: 'center',
					textStyle: {
						color: '#333333',
						rich: {
							name: {
								fontSize: 12,
								fontWeight: 'normal',
								color: '#666666',
								padding: [10, 0],
								align: 'center',
							},
							val: {
								fontSize: 32,
								fontWeight: 'bold',
								color: '#333333',
								align: 'center',
							},
						},
					},
				},
				tooltip: {
					trigger: 'item',
					show: false,
					formatter: '{b} : <br/>{d}%',
					backgroundColor: 'rgba(0,0,0,0.7)', // 背景
					padding: [8, 10], //内边距
					extraCssText: 'box-shadow: 0 0 3px rgba(255, 255, 255, 0.4);', //添加阴影
				},
				legend: {
					backgroundColor: '#F9FAFC',
					padding: [25, 32],
					width: 300,
					type: 'plain',
					icon: 'circle',
					orient: 'vertical',
					left: '45%',
					top: 'center',
					align: 'left',
					itemGap: 15,
					itemWidth: 10, // 设置宽度
					itemHeight: 10, // 设置高度
					symbolKeepAspect: false,
					textStyle: {
						color: '#333',
						rich: {
							name: {
								verticalAlign: 'right',
								align: 'left',
								width: 120,
								fontSize: 12,
							},
							value: {
								align: 'right',
								width: 60,
								fontSize: 12,
							},
						},
					},
					data: data.map((item) => item.name),
					formatter: function (name) {
						if (data && data.length) {
							for (var i = 0; i < data.length; i++) {
								if (name === data[i].name) {
									return '{name| ' + name + '}' + '{value| ' + data[i].value + '(次)}';
								}
							}
						}
					},
				},
				series: [
					{
						name: '',
						type: 'pie',
						roundCap: true,
						clockWise: false,
						radius: ['60%', '65%'],
						center: ['24%', '50%'],
						itemStyle: dataStyle,
						hoverAnimation: false,
						startAngle: 90,
						label: {
							borderRadius: 10,
						},
						data: [
							{
								value: data[0].value != 0 ? (data[0].value / this.datas.smartDeviceUseTotal) * 100 : 0,
								name: '省',
								itemStyle:
									data[0].value != 0
										? {
												normal: {
													color: '#165DFF',
												},
										  }
										: placeHolderStyle,
							},
							{
								value: 100 - (data[0].value / this.datas.smartDeviceUseTotal) * 100,
								name: '',
								tooltip: {
									show: false,
								},
								itemStyle: placeHolderStyle,
							},
						],
					},
					{
						name: 'Line 2',
						type: 'pie',
						roundCap: true,
						clockWise: false,
						radius: ['50%', '55%'],
						center: ['24%', '50%'],
						itemStyle: dataStyle,
						hoverAnimation: false,
						startAngle: 90,
						data: [
							{
								value: data[1].value != 0 ? (data[1].value / data[0].value) * 100 : 0,
								name: '市',
								itemStyle:
									data[1].value != 0
										? {
												normal: {
													color: '#02CADF',
												},
										  }
										: placeHolderStyle,
							},
							{
								value: 100 - (data[1].value / data[0].value) * 100,
								name: '',
								tooltip: {
									show: false,
								},
								itemStyle: placeHolderStyle,
							},
						],
					},
					{
						name: 'Line 3',
						type: 'pie',
						roundCap: true,
						clockWise: false,
						radius: ['40%', '45%'],
						center: ['24%', '50%'],

						itemStyle: dataStyle,
						hoverAnimation: false,
						startAngle: 90,
						data: [
							{
								value: data[2].value != 0 ? (data[2].value / data[1].value) * 100 : 0,
								name: '区县',
								itemStyle:
									data[2].value != 0
										? {
												normal: {
													color: '#F7BA1E',
												},
										  }
										: placeHolderStyle,
							},
							{
								value: 100 - (data[2].value / data[1].value) * 100,
								name: '',
								tooltip: {
									show: false,
								},
								itemStyle: placeHolderStyle,
							},
						],
					},
					{
						name: 'Line 3',
						type: 'pie',
						roundCap: true,
						clockWise: false,
						radius: ['30%', '35%'],
						center: ['24%', '50%'],
						itemStyle: dataStyle,
						hoverAnimation: false,
						startAngle: 90,
						data: [
							{
								value: data[3].value != 0 ? (data[3].value / data[2].value) * 100 : 0,
								name: '乡镇',
								itemStyle:
									data[3].value != 0
										? {
												normal: {
													color: '#04C59F',
												},
										  }
										: placeHolderStyle,
							},
							{
								value: 100 - (data[3].value / data[2].value) * 100,
								name: '',
								tooltip: {
									show: false,
								},
								itemStyle: placeHolderStyle,
							},
						],
					},
				],
			});
			window.addEventListener('resize', () => {
				myChart.resize();
			});
		},
		// 切换使用收益
		changeDate(val) {
			this.checkDate = val;
			this.getData();
		},
		// 获取使用收益
		getUsageIncomeData(data) {
			let myChart = echarts.init(document.getElementById('usageIncome'));
			myChart.setOption({
				tooltip: {
					trigger: 'axis',
					axisPointer: {
						type: 'shadow',
					},
				},
				grid: {
					top: 30,
					left: 20,
					right: 10,
					bottom: 0,
					containLabel: true,
				},
				xAxis: {
					data: data.map((item) => item.incomeTime),
					axisLine: {
						lineStyle: {
							color: 'rgba(81,81,81,0.3)',
						},
					},
					axisLabel: {
						color: '#999999',
						fontSize: 12,
						align: 'center',
					},
				},
				yAxis: {
					type: 'value',
					name: '单位(元)',
					nameTextStyle: {
						color: '#999999',
						fontSize: 12,
						align: 'right',
					},
					axisLine: {
						lineStyle: {
							color: '#fff',
						},
					},
					axisLabel: {
						color: '#999999',
						fontSize: 12,
						formatter: function (value, index) {
							return value > 999 ? value / 1000 + 'k' : value;
						},
					},
					splitLine: {
						lineStyle: {
							color: 'rgba(81,81,81,0.3)',
							type: 'dashed',
						},
					},
				},
				series: [
					{
						type: 'bar',
						barWidth: 18,
						itemStyle: {
							normal: {
								color: '#165DFF',
							},
						},
						data: data.map((item) => item.smartDeviceIncome && (item.smartDeviceIncome / 100).toFixed(2)),
					},
				],
				dataZoom: [
					{
						type: 'slider',
						realtime: true,
						start: 0,
						end: 90, // 数据窗口范围的结束百分比。范围是：0 ~ 100。
						height: 4, //组件高度
						left: 5, //左边的距离
						right: 5, //右边的距离
						bottom: 20, //下边的距离
						show: data.length > 7, // 是否展示
						fillerColor: '#203A66', // 滚动条颜色
						borderRadius: 4, // 滚动条圆角
						handleSize: 0, //两边手柄尺寸
						showDetail: false, //拖拽时是否展示滚动条两侧的文字
						zoomLock: true, //是否只平移不缩放
						moveOnMouseMove: false, //鼠标移动能触发数据窗口平移

						//下面是自己发现的一个问题，当点击滚动条横向拖拽拉长滚动条时，会出现文字重叠，导致效果很不好，以此用下面四个属性进行设置，当拖拽时，始终保持显示六个柱状图，可结合自己情况进行设置。添加这个属性前后的对比见**图二**
						startValue: 0, // 从头开始。
						endValue: 6, // 最多六个
						minValueSpan: 6, // 放大到最少几个
						maxValueSpan: 6, //  缩小到最多几个
					},
					{
						type: 'inside', // 支持内部鼠标滚动平移
						start: 0,
						end: 35,
						zoomOnMouseWheel: false, // 关闭滚轮缩放
						moveOnMouseWheel: true, // 开启滚轮平移
						moveOnMouseMove: true, // 鼠标移动能触发数据窗口平移
					},
				],
			});
			window.addEventListener('resize', () => {
				myChart.resize();
			});
		},
	},
};
</script>

<style lang="scss" scoped>
.deviceData {
	.container {
		margin: 16px;
	}
	.demo-form-inline {
		/deep/ .el-select {
			width: 106px !important;
			.el-input {
				width: 106px !important;
				.el-input__inner {
					width: 106px !important;
				}
			}
		}
		/deep/ .el-input {
			width: 106px;
		}
	}
	&-title {
		padding: 16px;
		background-color: #fff;
		border-radius: 4px;
		margin-bottom: 12px;
		&-content {
			.institutionTotal {
				display: flex;
				align-items: center;
				width: 100%;
				margin-right: 12px;
				background: #f9fafc;
				border-radius: 4px;
				padding: 16px;
				img {
					margin-right: 16px;
				}
				&-content {
					p {
						color: #999;
						font-size: 14px;
						height: 14px;
						line-height: 14px;
						&:last-child {
							font-size: 24px;
							line-height: 24px;
							height: 24px;
							margin-top: 12px;
							color: #333;
						}
					}
				}
			}
		}
	}
	.content {
		.chartsClass {
			background-color: #fff;
			margin-bottom: 12px;
			padding: 20px 16px;
			border-radius: 4px;
			box-shadow: 0px 6px 48px 0px rgba(81, 81, 81, 0.04);
			&:last-child {
				margin-bottom: 0;
			}
			p {
				font-size: 16px;
				line-height: 28px;
				font-weight: 500;
				color: #333333;
				margin-bottom: 22px;
				display: flex;
				align-items: center;
				img {
					margin-right: 12px;
				}
			}
			.chineseMedical {
				width: calc(50% - 30px);
				height: 100%;
				margin-right: 60px;
			}
			.legendClass {
				width: 100%;
				max-width: 300px;
				margin: 0 auto;
				padding: 24px 32px;
				background-color: #f9fafc;
				border-radius: 4px;
				&-content {
					display: flex;
					justify-content: space-between;
					align-items: center;
					&:first-child {
						margin-bottom: 20px;
					}
					> p {
						font-size: 16px;
						line-height: 16px;
						height: 16px;
						color: #333;
						margin-bottom: 0 !important;
						span {
							font-size: 12px;
							color: #999;
						}
					}
					.legendClass-title {
						font-size: 12px !important;
						line-height: 12px;
						height: 12px;
						margin-bottom: 0;
						span {
							display: inline-block;
							width: 14px;
							height: 14px;
							border-radius: 50%;
							border: 1px solid #165dff;
							margin-right: 8px;
						}
					}
					&:last-child {
						.legendClass-title {
							span {
								background-color: #165dff;
							}
						}
					}
				}
			}
		}
		.usageIncomeClass {
			margin-top: 12px;
			position: relative;
			.dateChange {
				position: absolute;
				right: 10px;
				top: 40px;
				display: flex;
				p {
					color: #999999;
					cursor: pointer;
					margin-bottom: 0;
					padding: 4px 12px;
					font-size: 14px;
					line-height: 18px;
				}
			}
		}
		.activeClass {
			color: #333 !important;
			background-color: #f5f6f8;
			border-radius: 4px;
		}
	}
	.mr0 {
		margin-right: 0 !important;
	}
}
</style>